import React from 'react';
import Navbar from '../components/Navbar';
import { useState, useEffect } from 'react';
import { IoMdArrowDropdown } from "react-icons/io";
import { toast, ToastContainer } from 'react-toastify'
import Verfied_Tick from '../assets/verified.svg'
import Social from '../components/Social'
import 'react-toastify/dist/ReactToastify.css';

const Verify = () => {

    const [Cert_ID, setCert_ID] = useState('')
    const [CS_Name, setCS_Name] = useState(null);
    const [verifytoggle, setVerifyToggle] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [course, setCourse] = useState([]);
    const [showPopUp, setShowPopUp] = useState(false)
    var json = '';

    useEffect(() => {
      const handelGetCourse = async () => {
        try {
          const response = await fetch('https://hsu-cert.vercel.app/api/getcourse')

          const data = await response.json();

        if(!response.ok) {
          throw Error(data.error);
        }
        else {
          setCourse(data);
        }
        } catch (error) {
          console.log(error.message);
        }
      }

      handelGetCourse();
    },[])

    const handleOptionClick = (option) => {
        setCS_Name(option);
        setVerifyToggle(!verifytoggle)
      };

      const notify = () => {
    
        if(json.hasOwnProperty('error') === true) {
          toast.error(json.error)
        }
        else {
          toast.info(json.message);
        }
    }

    const handelClick = async (e) => {

      e.preventDefault()

      const verify = { CS_Name, Cert_ID }
      setLoading(true)
  
      const response = await fetch('https://hsu-cert.vercel.app/api/verify', {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(verify)
      })
      setLoading(false)
      json = await response.json()
      notify()
  
      if(!response) {
        try {
          setError(json.error)
        } catch (error) {
          throw Error(error)
        }
      }
  
      if(response.ok) {
        console.log('Certificate ID verified Successfully')
        setCS_Name(null)
        setCert_ID('')
        if(json.message !== 'Certificate ID already verified!') {
          setShowPopUp(true)
        }
      }
  
    }

    const toTitleCase = (str) => {
      return str
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '); // Join back with a space
  };


  return (
    <div onClick={() => setShowPopUp(false)} className='bg-stone-900'>
      <Navbar />
      <ToastContainer theme='dark' />
      <div className='w-full h-full flex justify-center items-center flex-col max-sm:mt-[10vh]'>
        <span className='flex justify-center items-center text-white sm:text-[50px] font-bold font-["Poppins"] sm:mt-20 text-[22px]'>Verify your certificate</span>
        <div className={`${
              showPopUp === true ? "hidden" : "flex"
            } sm:w-[730px] sm:h-[600px] w-[272px] h-[338px] bg-black bg-opacity-10 max-sm:backdrop-blur-[15px] rounded-2xl shadow border border-white mt-10 mb-40 flex justify-center items-center flex-col sm:gap-5 gap-3 max-sm:bg-opacity-40`}>

            <input type="text" value={Cert_ID} className='sm:w-[498px] sm:h-[55px] bg-zinc-300 w-[221px] h-[29px] bg-opacity-5 sm:rounded-[15px] rounded-lg border border-zinc-300 text-white text-center sm:text-[18px] text-xs placeholder:text-stone-500 font-["Montserrat"]' onChange={(e) => setCert_ID(e.target.value)} placeholder='Enter your id *'/>

            <div className='sm:w-[498px] sm:h-[55px] bg-zinc-300 bg-opacity-5 rounded-[15px] border border-zinc-300 placeholder:text-neutral-500 sm:text-[18px] font-medium font-["Montserrat"] text-white text-center w-[221px] h-[29px] max-sm:rounded-lg text-xs cursor-pointer flex justify-center items-center'>
            <div className='flex justify-center items-mid text-neutral-500 sm:text-[18px] cursor-pointer text-xs ml-5' style={{color: CS_Name ? 'white' : '#696969'}} onClick={() => setVerifyToggle(!verifytoggle)}>
              {CS_Name ? toTitleCase(CS_Name) : CS_Name || 'Select course * '}
            </div>
            {verifytoggle && <ul className='absolute sm:mt-[30vh] bg-stone-800 sm:w-[498px] sm:h-auto flex flex-col sm:rounded-2xl p-5 mt-[20vh] w-[221px] rounded-lg'>
              {course.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option.course)} className='sm:p-3  max-h-60 overflow-y-scroll flex flex-col cursor-pointer text-neutral-500 hover:bg-neutral-100 sm:rounded-xl p-1 rounded-lg justify-center items-center'>
                  {toTitleCase(option.course)}
                </li>
              ))}
            </ul>}
            <IoMdArrowDropdown color='#696969' className='ml-2 sm:text-[30px] text-[18px]' onClick={() => handleOptionClick(CS_Name)}/>
          </div>
          <button className='sm:w-[498px] sm:h-[60px] bg-purple-600 sm:rounded-[15px] border border-zinc-300 sm:mt-5 text-white sm:text-lg font-semibold font-["Montserrat"] w-[221px] h-[29px] rounded-lg text-xs hover:bg-purple-800 capitalize disabled:opacity-75 max-sm:mt-3 max-sm:bg-opacity-40 ease-in duration-200' onClick={handelClick} disabled={loading}>{loading ? <>Loading...</>: <>verify</>}</button>
          {error}
        </div>
        {showPopUp === true && 
        (
          <div className='flex justify-center items-center sm:w-[730px] sm:h-[632px] bg-black bg-opacity-10 rounded-2xl shadow border border-white flex-col gap-5 mt-10 w-[272px] h-[338px] max-sm:mb-40 bg-black max-sm:backdrop-blur-[15px]'>
            <img src={Verfied_Tick} alt="Green Tick" className='max-sm:w-[50px]' />
            <div class="text-white sm:text-[26px] font-medium font-['Montserrat']">your certificate is verified </div>
            <div className='sm:mt-10'>
            <Social />
            </div>
          </div>
        )
        }
    </div>
    </div>
  );
}

export default Verify;
