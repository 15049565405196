import { createContext, useReducer } from "react";

// Create the context
export const CoursesContext = createContext();

// coursesReducer initialization with an empty array
export const coursesReducer = (state, action) => {
    switch (action.type) {
        case 'SET_COURSES':
            return {
                courses: action.payload
            }
        case 'CREATE_COURSE':
            return {
                ...state,
                courses: [action.payload, ...state.courses]
            }
        case 'DELETE_COURSE':
            return {
                courses: state.courses.filter((s) => s._id !== action.payload._id)
            }
        default:
            return state
    }
};

// Context provider initial state with an empty array
export const CoursesContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(coursesReducer, {
        courses: null // Initialize courses as an empty array
    });

    return (
        <CoursesContext.Provider value={{ ...state, dispatch }}>
            {children}
        </CoursesContext.Provider>
    );
};
