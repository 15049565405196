import React, { useState } from 'react';
import { MdContentCopy } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaRegTrashCan } from "react-icons/fa6";
import { useCourseContext } from '../hooks/useCourseContext'

const CourseDetalils = ({ course }) => {

  const [deleteModal, setDeleteModal] = useState(false);
  const { dispatch } = useCourseContext();
  var json = ''

const toTitleCase = (str) => {
    return str
        .toLowerCase()
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' '); // Join back with a space
};

const notify = () => {
    
  if(json.hasOwnProperty('error') === false) {
    toast.success(`${json.course} deleted Successfully!`);
  }
  else {
    toast.error(json.error)
  }
}

const copyToClipboard = (course_key) => {
    navigator.clipboard.writeText(course_key);
    toast.success('copied');
  };

  const handelOpenDeleteModal = () => {
    setDeleteModal(true);
  }

  const handelCloseDeleteModal = () => {
    setDeleteModal(false);
  }

  const handelDelete = async () => {

    const response = await fetch(`https://hsu-cert.vercel.app/api/deletecourse/${course._id}`,
      {
        method: 'DELETE',
      })

      json = await response.json()

      if(response.ok) {
        notify();
        setTimeout(() => {
          dispatch({type:'DELETE_COURSE', payload: json});
        }, 2000);
      }

  }


  return (
    <div className='flex relative container flex-col items-start sm:text-[16px] text-xs font-normal gap-2 mt-2'>
        <ToastContainer theme='light' />
      <ul className='flex sm:gap-28 gap-16 text-stone-800'>
        <li className='sm:w-24 w-8 sm:text-[16px] text-xs'>{course.course ? toTitleCase(course.course) : course.course}</li>
        <li className='flex sm:gap-2'>{course.course_key}<MdContentCopy className='sm:text-[16px] text-xs cursor-pointer' onClick={() => copyToClipboard(course.course_key)} /></li>
        <li onClick={handelOpenDeleteModal} className='cursor-pointer sm:text-[16px] text-xs'><FaRegTrashCan color='E50000' /></li>
      </ul>
      {deleteModal && (
        <div className='absolute z-20 sm:top-32 sm:left-20 top-12 left-20 flex flex-col justify-center items-center sm:w-[18rem] sm:h-[15vh] bg-zinc-300 shadow-md rounded text-stone-800 gap-3 max-sm:p-4'>
          <span className='flex justify-center items-center text-center'>Are you sure?</span>
          <div className='flex gap-4'>
          <button className='bg-red-600 text-white sm:px-3 sm:py-2 px-2 py-1 rounded-md hover:bg-red-700 ease-in duration-200' onClick={handelDelete}>Yes</button>
          <button className='bg-purple-600 text-white sm:px-3 sm:py-2 px-2 py-1 rounded-md hover:bg-purple-700 ease-in duration-200' onClick={handelCloseDeleteModal}>No</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseDetalils;
