import React from 'react';
import { useNavigate } from 'react-router-dom'
import { FaBackward, FaHome } from 'react-icons/fa'

const Error = () => {

  const navigator = useNavigate()

  const handelPrevious = () => {

      navigator(-1)
  }

  const handelHome = () => {

    navigator('/')
  }
  
  return (
    <div className='w-full h-screen flex justify-center items-center flex-col'>
      <div className='flex flex-row sm:gap-8 gap-5 bg-opacity-10 max-sm:backdrop-blur-[15px]'>
      <div class="text-neutral-400 sm:text-[200px] font-bold font-['Poppins'] text-[100px] flex justify-center items-center">4</div>
      <div class="text-neutral-400 sm:text-[200px] font-bold font-['Poppins'] text-[100px] flex justify-center items-center">0</div>
      <div class="text-neutral-400 sm:text-[200px] font-bold font-['Poppins'] text-[100px] flex justify-center items-center">4</div>
      </div>
      <div className='flex sm:gap-10 gap-6'>
      <button className='bg-purple-600 px-4 py-1 text-white font-["Poppins"] sm:text-3xl text-sm rounded-lg hover:opacity-75 sm:-mt-8 flex justify-center items-center sm:gap-2 gap-1' onClick={handelPrevious}><FaBackward />Back</button>
      <button className='bg-purple-600 px-4 py-1 text-white font-["Poppins"] sm:text-3xl text-sm rounded-lg hover:opacity-75 sm:-mt-8 flex justify-center items-center sm:gap-2 gap-1' onClick={handelHome}><FaHome />Home</button>
      </div>

    </div>
  );
}

export default Error;
