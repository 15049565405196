import React from 'react';
import { FaLinkedin, FaShareSquare } from 'react-icons/fa'
import { FaXTwitter } from "react-icons/fa6";
import { ImFacebook2 } from "react-icons/im";
import Insta from '../assets/insta.svg'


const Social = () => {
  return (
    <div className='flex flex-col'>
      <div className="text-zinc-400 sm:text-base font-medium font-['Montserrat'] flex justify-center items-center text-[10px]">share you certificate & tag us @hexstaruniverse</div>
  
      <ul className='flex justify-center items-center mt-3 sm:gap-4 gap-2'>
        <li className='sm:text-[26px]'><FaShareSquare color='white' /></li>
        <li className='sm:text-[28px] text-[18.5px] cursor-pointer sm:ml-4 ml-2'><a href="https://www.linkedin.com"><FaLinkedin color='#0A66C2'/></a></li>
        <li className='bg-white rounded-sm sm:text-[23px] text-[15px] cursor-pointer'><a href="https://twitter.com"><FaXTwitter color='black'/></a></li>
        <li className='flex sm:w-[26px] w-[17px] cursor-pointer'><a href="https://www.instagram.com"><img src={Insta} alt="Instagram Icon" /></a></li>
        <li className='bg-white rounded-[13px] sm:text-[26.5px] cursor-pointer '><a href="https://www.facebook.com"><ImFacebook2 color='#3D5A98'/></a></li>
      </ul>
      
    </div>
  );
}

export default Social;
