import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminNavbar from '../components/AdminNavbar';
import { PiLockKeyFill } from 'react-icons/pi';
import { MdContentCopy } from 'react-icons/md';
import { v4 as uuidv4 } from 'uuid';
import { IoMdArrowDropdown } from "react-icons/io";
import { useCourseContext } from '../hooks/useCourseContext';
import CourseDetalils from '../components/CourseDetalils';

const AdminPanel = () => {
    const [course, setCourse] = useState('');
    const [course_key, setCourseKey] = useState('');
    const [duration, setDuration] = useState('');
    const [domain, setDomain] = useState('');
    const [instructor, setInstructor] = useState('');
    const [pricingType, setPricingType] = useState('');
    const [admintoggle, setAdminToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [instructor_desc, setInstructor_Desc] = useState('')
    const [error, setError] = useState(null);
    const { courses, dispatch } = useCourseContext();
    const type = ['Paid', 'Free'];

    // Fetching courses
    useEffect(() => {
        const handelGetCourse = async () => {
            try {
                const response = await fetch('https://hsu-cert.vercel.app/api/getcourse');
                const data = await response.json();
                if (!response.ok) {
                    throw Error(data.error);
                } else {
                    dispatch({ type: 'SET_COURSES', payload: data });
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        handelGetCourse();
    }, [dispatch]);
    
    const generateKey = () => {

      var uuid = uuidv4().replace(/\D/g, ''); 
      uuid = uuid.substring(0, 6);
      setCourseKey(uuid);
  
      return uuid;
    };

    const notify = (message, ch) => {
        switch (ch) {
            case 'Success':
                return toast.success(message);
            case 'Error':
                return toast.error(message);
            default:
                return 0;
        }
    };


    const copyToClipboard = () => {
        navigator.clipboard.writeText(course_key);
        notify('Copied!', 'Success');
    };

    const handelSelect = (option) => {
        setPricingType(option);
        setAdminToggle(!admintoggle);
    };

    const handelCreateCourse = async (e) => {
        e.preventDefault();

        try {
            if (!course || !course_key || !duration || !domain || !instructor || !pricingType) {
                throw Error('All fields must be filled!');
            }

            const create_course = { course, course_key, duration, domain, instructor, instructor_desc, pricingType };
            setLoading(true);

            const response = await fetch('https://hsu-cert.vercel.app/api/create-course', {
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify(create_course),
            });

            const json = await response.json();

            if (!response.ok) {
              setError(json.error);
                throw Error(json.error);
            }

            if(response.ok){
              notify('Course created!', 'Success');
              setCourse('');
              setCourseKey('');
              setDuration('');
              setDomain('');
              setInstructor('');
              setPricingType('');
              setInstructor_Desc('');
              setTimeout(() => {
                dispatch({type: 'CREATE_COURSE', payload: json});
              }, 1000)
              setAdminToggle(false);
            }
            
        } catch (error) {
            notify(error.message, 'Error');
        } finally {
            setLoading(false);
            setAdminToggle(false);
        }
    };

  return (
    <div className='flex h-full flex-col justify-center items-center bg-zinc-200'>
      <ToastContainer theme="light" />
      <AdminNavbar />
      <p className='font-["Montserrat"] sm:text-4xl text-[23px] text-center w-full sm:p-10 font-bold max-sm:m-5'>
        Welcome Admin 🙂
      </p>
      <div className='flex max-sm:flex-col h-screen gap-8 justify-center items-center'>
        <div className='flex flex-col relative container justify-center items-center w-[300px] h-[360px] sm:w-[550px] sm:h-[560px] sm:gap-5 gap-3 sm:shadow-xl rounded-md'>
          <span className='absolute font-["Montserrat"] sm:text-2xl text-xl  sm:p-3 p-2 top-0'>
            <strong>All Courses</strong>
            <div className='flex flex-col items-start sm:text-sm text-xs text-purple-600 font-medium mt-4 gap-2 overflow-y-scroll sm:h-[440px] h-[320px]'>
              <ul className='flex flex-inline sm:gap-28 gap-8'>
                <li>Course Name</li>
                <li>Course Key</li>
                <li>Delete</li>
              </ul>
              <div className='sm:w-[450px] w-[260px] border border-purple-600 border-dashed'>
            </div>
            {courses && courses.map((course) => (
                  <CourseDetalils key={course._id} course={course} />
                ))}
            </div>
          </span>
        </div>
        <div className='flex flex-col relative container justify-center items-center w-[300px] h-[660px] sm:w-[550px] sm:h-[560px] sm:gap-5 gap-3 shadow-xl bg-blur-md rounded-md'>
          <span className='absolute font-["Montserrat"] sm:text-2xl text-xl sm:p-2  top-0'>
            <strong>Add Course</strong>
          </span>

          <input
            type='text'
            required
            autoComplete='Masterclass'
            placeholder='Enter Course Name *'
            className='flex font-["Montserrat"] sm:w-[420px] sm:h-[36px] w-[230px] h-[30px] rounded-md sm:indent-[1.5rem] bg-transparent shadow-md placeholder:text-stone-600 text-stone-800 border border-stone-200 max-sm:text-xs indent-5'
            value={course}
            onChange={(e) => setCourse(e.target.value)}
          />

          <div className='flex container relative w-full justify-center items-center gap-3'>
            <input
              type='text'
              required
              placeholder='Enter Course Key *'
              className='flex font-["Montserrat"] sm:w-[420px] sm:h-[36px] w-[230px] h-[30px] rounded-md sm:indent-[1.5rem] bg-transparent shadow-md placeholder:text-stone-600 text-stone-800 border border-stone-200 max-sm:text-xs indent-5'
              value={course_key}
              onChange={(e) => setCourseKey(e.target.value)}
            />
            <button
              className='absolute sm:text-xl bg-transparent inset-y-0 sm:right-20 right-10 hover:bg-stone-800 hover:text-white ease-in duration-200 rounded-[50%] p-2'
              onClick={copyToClipboard}
            >
              <MdContentCopy />
            </button>
            <button
              className='absolute sm:text-xl sm:ml-[30rem] ml-[16.5rem] rounded-[50%] sm:p-2 p-1 shadow-md hover:bg-stone-800 hover:text-white ease-in duration-200'
              onClick={generateKey}
            >
              <PiLockKeyFill />
            </button>
            {error}
          </div>

          <input
            type='text'
            required
            placeholder='Enter Duration *'
            className='flex font-["Montserrat"] sm:w-[420px] sm:h-[36px] w-[230px] h-[30px] rounded-md sm:indent-[1.5rem] bg-transparent shadow-md placeholder:text-stone-600 text-stone-800 border border-stone-200 max-sm:text-xs indent-5'
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />

          <input
            type='text'
            required
            placeholder='Enter Domain *'
            className='flex font-["Montserrat"] sm:w-[420px] sm:h-[36px] w-[230px] h-[30px] rounded-md sm:indent-[1.5rem] bg-transparent shadow-md placeholder:text-stone-600 text-stone-800 border border-stone-200 max-sm:text-xs indent-5'
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />

          <input
            type='text'
            required
            placeholder='Enter Instructor Name *'
            className='flex font-["Montserrat"] sm:w-[420px] sm:h-[36px] w-[230px] h-[30px] rounded-md sm:indent-[1.5rem] bg-transparent shadow-md placeholder:text-stone-600 text-stone-800 border border-stone-200 max-sm:text-xs indent-5'
            value={instructor}
            onChange={(e) => setInstructor(e.target.value)}
          />

<input
            type='text'
            required
            placeholder='Enter Instructor Description *'
            className='flex font-["Montserrat"] sm:w-[420px] sm:h-[36px] w-[230px] h-[30px] rounded-md sm:indent-[1.5rem] bg-transparent shadow-md placeholder:text-stone-600 text-stone-800 border border-stone-200 max-sm:text-xs indent-5'
            value={instructor_desc}
            onChange={(e) => setInstructor_Desc(e.target.value)}
          />

          <div
            className='flex relative font-["Montserrat"] sm:w-[420px] sm:h-[36px] w-[230px] h-[30px] rounded-md sm:indent-[1.5rem] bg-transparent shadow-md text-stone-600 border border-stone-200 max-sm:text-xs indent-5 items-center cursor-pointer'
            style={{ color: pricingType ? '#383838' : '#383838' }}
            onClick={() => setAdminToggle(!admintoggle)}
          >
            {pricingType ? pricingType : 'Select Price Type *'}
            <div className='text-2xl cursor-pointer flex absolute inset-y-1 right-4'><IoMdArrowDropdown /></div>
          </div>

          {admintoggle && (
            <ul className='absolute bg-zinc-200 sm:w-[420px] sm:h-[16vh] h-[12vh] flex flex-col sm:rounded-md sm:mt-[60vh] mt-[45vh] p-3 w-[230px] rounded-md border border-stone-600'>
              {type.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handelSelect(option)}
                  className='sm:p-2 flex cursor-pointer text-stone-600 hover:bg-neutral-800 hover:text-neutral-100 sm:rounded-md p-1 rounded-sm justify-center items-center font-["Montserrat"] max-sm:text-sm'
                >
                  {option}
                </li>
              ))}
            </ul>
          )}
          <button className='flex font-["Montserrat"] sm:w-[420px] sm:h-[46px] w-[230px] h-[40px] rounded-md bg-purple-600 text-white font-bold border border-stone-200 max-sm:text-xs capitalize justify-center items-center mt-3 hover:bg-purple-800 ease-in duration-200' disabled={loading} onClick={handelCreateCourse}>{loading ? <>Loading...</> : <>create course</>}</button>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;

