import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import { IoMdArrowDropdown } from "react-icons/io";
import outLines from '../assets/outlines.svg'
import Ecilps1 from '../assets/ecilps1.svg'
import Ecilps2 from '../assets/eclips2.svg'
import Eclips3 from '../assets/eclips3.svg'
import cube1 from '../assets/cube1.svg'
import cube2 from '../assets/cube2.svg'
import { motion, useAnimation, useTime, useTransform } from "framer-motion";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Social from '../components/Social';
import { PDFDocument, rgb } from 'pdf-lib';
import { saveAs } from 'file-saver';
import fontkit from '@pdf-lib/fontkit';
import MontserratBold from '../assets/Montserrat-Bold.ttf';
import  MontserratMedium from '../assets/Montserrat-Medium.ttf';
import signature from '../assets/Arty_Signature.otf';


const Landing = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [course_name, setCoursename] = useState(null);
    const [course_key, setCoursekey] = useState('');
    const [landingtoggle, setLandingToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);
    const [course, setCourse] = useState([]);
    const [error, setError] = useState(null);
    const time = useTime();
    const rotate = useTransform(time, [0, 50000], [0, 360], { clamp: false });
    const controlse1 = useAnimation();
    const controlse2 = useAnimation();
    const controlse3 = useAnimation();
    const controlcube = useAnimation();
    var json = '';
    var url = '';

    const uppercaseToCamelCaseWithSpaces = (uppercaseString) => {
      return uppercaseString
        .toLowerCase()
        .replace(/\b\w/g, (match) => match.toUpperCase());
    };

    const modifyPdf = async () => {

      const pdfUrlfree = 'https://res.cloudinary.com/drnfrryna/image/upload/v1727340191/Group_48095422_my8nyr.pdf'; // free certificate template
      const pdfUrlPaid = 'https://res.cloudinary.com/drnfrryna/image/upload/v1728373666/Group_48095424_bs2jiq.pdf'; // paid certificate template

      if(json.pricingType === 'Paid') {
        url = pdfUrlPaid;
      }
      else {
        url = pdfUrlfree;
      }
  
      const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer()).catch((err) => console.log(err.message));
      const pdfDoc = await PDFDocument.load(existingPdfBytes);
      pdfDoc.registerFontkit(fontkit);

      // certificate fonts
  
      const boldFontBytes = await fetch(MontserratBold).then((res) => res.arrayBuffer());
      const boldFont = await pdfDoc.embedFont(boldFontBytes);
  
      const mediumFontBytes = await fetch(MontserratMedium).then((res) => res.arrayBuffer());
      const mediumFont = await pdfDoc.embedFont(mediumFontBytes);
  
      const signatureFontBytes = await fetch(signature).then((res) => res.arrayBuffer());
      const signatureFont = await pdfDoc.embedFont(signatureFontBytes);
  
      const year = new Date().getFullYear();
      const month_no = new Date().getMonth();
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      const month = months[month_no];
  
      const pages = pdfDoc.getPages();
      const firstPage = pages[0];
  
      firstPage.drawText(uppercaseToCamelCaseWithSpaces(json.name), {
        x: 70,
        y: 311,
        size: 26,
        font: boldFont,
        color: rgb(0, 0, 0),
      });
  
      firstPage.drawText(`has attended ${json.course} by ${json.instructor},`, {
        x: 70,
        y: 273,
        size: 14,
        font: mediumFont,
        color: rgb(0.37, 0.37, 0.37),
      });
  
      firstPage.drawText('organized by Hex-Star Universe.', {
        x: 70,
        y: 255,
        size: 14,
        font: mediumFont,
        color: rgb(0.37, 0.37, 0.37),
      })
  
      firstPage.drawText(`${json.duration}`, {
        x: 190,
        y: 208,
        size: 14,
        font: mediumFont,
        color: rgb(0.37, 0.37, 0.37),
      });
  
      firstPage.drawText(`${json.domain}`, {
        x: 190,
        y: 180,
        size: 14,
        font: mediumFont,
        color: rgb(0.37, 0.37, 0.37),
      });
  
      firstPage.drawText(`${month} ${year}`, {
        x: 671,
        y: 297,
        size: 16,
        font: mediumFont,
        color: rgb(0.37, 0.37, 0.37),
      });
  
      firstPage.drawText(json.certificate_id, {
        x: 620,
        y: 229,
        size: 16,
        font: mediumFont,
        color: rgb(0.37, 0.37, 0.37),
      });
  
      firstPage.drawText(json.instructor, {
        x: 75,
        y: 110,
        size: 36,
        font: signatureFont,
        color: rgb(0.39, 0.39, 0.39)
      })
  
      firstPage.drawText(json.instructor, {
        x: 75,
        y: 85,
        size: 12,
        font: mediumFont,
        color: rgb(0, 0, 0)
      })

      firstPage.drawText(json.instructor_desc, {
        x: 75,
        y: 70,
        size: 12,
        font: mediumFont,
        maxWidth: 150,
        lineHeight: 13,
        color: rgb(0.545, 0.545, 0.545)
      })
  
      const pdfBytes = await pdfDoc.save();
      const blob = new Blob([pdfBytes], { type: 'application/pdf' });
      saveAs(blob, 'certificate.pdf');
    };

    const toTitleCase = (str) => {
      return str
          .toLowerCase()
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' '); // Join back with a space
  };

    useEffect(() => {
      const handelGetCourse = async () => {
        try {
          const response = await fetch('https://hsu-cert.vercel.app/api/getcourse')

          const data = await response.json();

        if(!response.ok) {
          throw Error(data.error)
        }
        else {
          setCourse(data);
        }
        } catch (error) {
          console.log(error.message);
        }
      }

      handelGetCourse();
    },[])

    const handleOptionClick = (option) => {
        setCoursename(option);
        setLandingToggle(!Landing);
      };

      const notify = () => {
    
        if(json.hasOwnProperty('error') === false) {
          toast.success('Certificate generated!')
        }
        else {
          toast.error(json.error)
        }
    }

    useEffect(() => {

      controlse1.start({
        y: [0, 30, 0],
        transition: {
          repeat: Infinity,
          duration: 5,
          ease: 'linear',
        },
      });

      controlse2.start({
        y: [0, 40, 0], 
        transition: {
          repeat: Infinity,
          duration: 4,
          ease: 'linear',
          delay: 2
        }
      })

      controlse3.start({
        y: [0, 50, 0],
        transition: {
          repeat: Infinity,
          duration: 6,
          ease: 'linear',
          delay: 1
        }
      })

      controlcube.start({
        rotate: 360,
        transition: {
          repeat: Infinity,
          duration: 6,
          ease: 'linear',
        }
      })

  },[controlse1, controlse2, controlse3, controlcube])

  const handelClick = async (e) => {
    e.preventDefault()

    try {
      if(!name || !email || !course_name || !course_key){
        throw Error('All fields must be filled!')
      }

      const cert_gen = { name, email, course_name, course_key }
      setLoading(true)

    const response = await fetch('https://hsu-cert.vercel.app/api/register', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(cert_gen)
    })
    setLoading(false)
    json = await response.json()
    notify()

    if(!response) {
      try {
        setError(json.error)
      } catch (error) {
        throw Error(error)
      }
    }

    if(response.ok) {
      console.log(json);
      modifyPdf()
      setEmail('');
      setName('');
      setCoursename('');
      setCoursekey('');
    }
    } catch (error) {
      json = {error: error.message}
      notify();
    }

  }

  const handelDownloadCertificate = async (e) => {
    e.preventDefault()

    try {
      if(!name || !email || !course_name || !course_key){
        throw Error('All fields must be filled!')
      }

      const cert_download = { name, email, course_name, course_key }
      setLoader(true)

    const response = await fetch('https://hsu-cert.vercel.app/api/download', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify(cert_download)
    })
    setLoader(false)
    json = await response.json()
    notify()

    if(!response) {
      try {
        setError(json.error)
      } catch (error) {
        throw Error(error)
      }
    }

    if(response.ok) {
      console.log(json);
      modifyPdf()
      setEmail('');
      setName('');
      setCoursename('');
      setCoursekey('');
    }
    } catch (error) {
      json = {error: error.message}
      notify();
    }
  }

  return (
    <div className='bg-zinc-950'>
      <ToastContainer theme='dark' />
      <Navbar />
      <div className='w-full sm:h-full h-screen flex justify-center items-center flex-col max-sm:mt-[10vh]'>
        <span className='flex justify-center items-center text-white sm:text-[50px] font-bold font-["Poppins"] sm:mt-8 text-[22px]'>Generate your certificate</span>
        <div className='flex flex-1 gap-[8rem] sm:mb-40 max-sm:mt-[4rem] mt-5'>
        <div className='max-sm:hidden flex'>
          <motion.div style={{rotate}}>
          <img src={outLines} alt="outlines" className='mt-20' />
          </motion.div>
          <motion.div className='absolute ml-[9rem]' animate={controlse1}>
          <img src={Ecilps1} alt="SPACE" />
          </motion.div>
          <motion.div animate={controlse2} className='absolute flex mt-40 ml-[18rem]'>
            <img src={Ecilps2} alt="ASTROBIOLOGY"/>
          </motion.div>
          <motion.div animate={controlse3} className='absolute mt-[40vh] ml-[7rem]'>
            <img src={Eclips3} alt="FLUID DYNAMICS" />
          </motion.div>
          <motion.div animate={controlcube} className='absolute mt-[20vh] ml-[2rem]'>
            <img src={cube2} alt="SCIENCE" />
          </motion.div>
          <motion.div animate={controlcube} className='absolute mt-[52vh] ml-[22rem]'>
            <img src={cube1} alt="SCIENCE" />
          </motion.div>
        </div>
        <div className='sm:w-[586px] sm:h-[700px] sm:bg-black sm:bg-opacity-10 rounded-2xl shadow border border-white flex justify-center items-center gap-8 sm:mt-10 w-[272px] h-[440px] bg-black bg-opacity-10 max-sm:backdrop-blur-[15px] flex-col sm:gap-10 gap-[1.5rem] max-sm:bg-opacity-40'>

          <span className='text-stone-300 font-["Montserrat"] font-bold sm:text-2xl '>Student Registration</span>

            <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Enter name *' className='sm:w-[463px] sm:h-[48.99px] bg-zinc-300 bg-opacity-5 rounded-[15px] border border-zinc-300 placeholder:text-neutral-500 sm:text-[18px] font-medium font-["Montserrat"] text-white text-center w-[221px] h-[29px] max-sm:rounded-lg text-xs' required/>

            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Enter email *' className='sm:w-[463px] sm:h-[48.99px] bg-zinc-300 bg-opacity-5 rounded-[15px] border border-zinc-300 placeholder:text-neutral-500 sm:text-[18px] font-medium font-["Montserrat"] text-white text-center w-[221px] h-[29px] max-sm:rounded-lg text-xs' required/>

            <input type="text" value={course_key} onChange={(e) => setCoursekey(e.target.value)} placeholder='Course key *' className='sm:w-[463px] sm:h-[48.99px] bg-zinc-300 bg-opacity-5 rounded-[15px] border border-zinc-300 placeholder:text-neutral-500 sm:text-[18px] font-medium font-["Montserrat"] text-white text-center w-[221px] h-[29px] max-sm:rounded-lg text-xs' required/>
            
            <div className='sm:w-[463px] sm:h-[48.99px] bg-zinc-300 bg-opacity-5 rounded-[15px] border border-zinc-300 placeholder:text-neutral-500 sm:text-[18px] font-medium font-["Montserrat"] text-white text-center w-[221px] h-[29px] max-sm:rounded-lg text-xs cursor-pointer flex justify-center items-center'>
            <div className='flex justify-center items-mid text-neutral-500 sm:text-[18px] cursor-pointer text-xs ml-5' style={{color: course_name ? 'white' : '#696969'}} onClick={() => setLandingToggle(!landingtoggle)}>
              {course_name ? toTitleCase(course_name) : course_name || 'Select course * '}
            </div>
            {landingtoggle && <ul className='absolute sm:mt-[25vh] bg-stone-800 sm:w-[463px] max-h-60 overflow-y-scroll flex flex-col sm:rounded-2xl p-5 mt-[20vh] w-[221px] rounded-lg'>
              {course.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option.course)} className='sm:p-3 flex cursor-pointer text-neutral-100 hover:bg-neutral-100 hover:text-neutral-800 sm:rounded-xl p-1 rounded-lg justify-center items-center'>
                  {toTitleCase(option.course)}
                </li>
              ))}
            </ul>}
            <IoMdArrowDropdown color='#696969' className='ml-2 sm:text-[30px] text-[18px]' onClick={() => handleOptionClick(course_name)}/>
          </div>
          <button className='sm:w-[463px] sm:h-[48.99px] bg-purple-600 sm:rounded-[15px] border border-zinc-300  text-white sm:text-lg font-semibold font-["Montserrat"] w-[221px] h-[29px] rounded-lg text-xs hover:bg-purple-800 capitalize disabled:opacity-75 ease-in duration-300' onClick={handelClick} disabled={loading}>{loading ? <>Loading...</>: <>Register & Download Certificate</>}</button>
          <div className='flex flex-col justify-center items-center text-stone-300 font-["Montserrat"] sm:text-sm sm:max-w-80 max-w-60 text-[8px] text-center sm:gap-5 gap-2'>
            <span>Already Registered ? Fill the above fields and download certificate.</span>
            <button className='sm:w-[463px] sm:h-[48.99px] bg-purple-600 sm:rounded-[15px] border border-zinc-300  text-white sm:text-lg font-semibold font-["Montserrat"] w-[221px] h-[29px] rounded-lg text-xs hover:bg-purple-800 capitalize disabled:opacity-75 ease-in duration-300' onClick={handelDownloadCertificate} disabled={loader}>{loader ? <>Loading...</>: <>Download certificate</>}</button>
          </div>
          {error}
        </div>
        </div>
        <div className='flex justify-center items-center mb-10'>
        <Social />
        </div>
      </div>
    </div>
  );
}

export default Landing;
