import {BrowserRouter, Routes, Route, useNavigate} from 'react-router-dom'
import Landing from './pages/Landing';
import Verify from './pages/Verify';
import Error from './pages/Error';
import { useEffect } from 'react';
import AdminLogin from './pages/AdminLogin';
import { useAuthContext } from "./hooks/useAuthContext";
import AdminPanel from './pages/AdminPanel';


function App() {

  const { user } = useAuthContext();

  useEffect(() => {
    const checkAndClearLocalStorage = () => {
      const currentStorageSize = getCurrentLocalStorageSize();

      // Set your desired storage limit, e.g., 5 MB (you can adjust this value)
      const storageLimit = 5 * 1024 * 1024; // 5 MB in bytes

      if (currentStorageSize >= storageLimit) {
        console.log('Local storage is full. Clearing...');
        localStorage.clear();
      }
    };

    const getCurrentLocalStorageSize = () => {
      // Calculate the approximate size of local storage in bytes
      const totalSize = Object.keys(localStorage).reduce(
        (accumulator, key) =>
          accumulator + localStorage.getItem(key).length + key.length,
        0
      );

      return totalSize;
    };

    // Check and clear local storage initially
    checkAndClearLocalStorage();

    // Set up an interval to check and clear local storage periodically
    const intervalId = setInterval(checkAndClearLocalStorage, 24 * 60 * 60 * 1000); // Check every 24 hours


    // Cleanup interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  const AdminLoginRedirect = ({ user }) => {
    const navigate = useNavigate();
  
    useEffect(() => {
      if (user) {
        navigate('/admin-panel');
      }
    }, [user, navigate]);
  
    return <AdminLogin />;
  };
  
  const AdminPanelProtected = ({ user }) => {
    const navigate = useNavigate();
  
    useEffect(() => {
      if (!user) {
        navigate('/login');
      }
    }, [user, navigate]);
  
    return user ? <AdminPanel /> : null;  // Return null if no user
  };
  
  return (
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Landing />} />
      <Route path='/login' element={<AdminLoginRedirect user={user} />} />
        <Route path='/admin-panel' element={<AdminPanelProtected user={user} />} />
      <Route path='/verify' element={<Verify />} />
      <Route path='*' element={<Error />} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
