import React from 'react';
import { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { RxCross1 } from "react-icons/rx";
import Logo from '../assets/hsulogo.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLogout } from '../hooks/useLogout';

const AdminNavbar = () => {
    const { user } = useAuthContext();
    const { logout } = useLogout();
    const [navToggle, setNavToggle] = useState(false); // Renamed state to avoid conflicts
    const navigate = useNavigate();

    const handelNavigate = () => {
        navigate('/verify');
    };

    const handelUser = () => {
        logout();
    };

    return (
        <div className='w-full relative bg-zinc-800'>
            <nav className="grid items-center justify-between h-20 grid-cols-2 text-white md:grid-cols-6 font-['Gilroy']">
                <a href="https://hexstaruniverse.com/">
                    <img className='w-20 col-span-2 ml-4 lg:w-[120px] lg:ml-14' src={Logo} alt="hexstar universe"/>
                </a>
                <ul className='flex-row items-center hidden col-span-3 capitalize space-x-7 md:flex'>
                    <a href='https://hexstaruniverse.com/all-classes/'>
                        <li className='hover:text-purple-400 ease-in duration-300'>all program</li>
                    </a>
                    <a href="https://hexstaruniverse.com/for-institutions/">
                        <li className='hover:text-purple-400 ease-in duration-300'>For institutions</li>
                    </a>
                    <a href="https://hexstaruniverse.com/about-us/">
                        <li className='hover:text-purple-400 ease-in duration-300'>about</li>
                    </a>
                </ul>
                <div className="flex items-center col-span-1 justify-evenly md:justify-center md:gap-5 md:col-span-2">
                    <button className='hover:text-purple-400 ease-in duration-300 max-sm:hidden' onClick={handelUser}>
                        {user ? <>Logout</> : <>Login</>}
                    </button>
                    <button
                        className='px-3 capitalize sm:text-xl bg-gradient-to-br from-lime-500 to-green-700 sm:h-8 rounded-md md:text-base hover:translate-x-[2px] text-[10px] font-medium h-6'
                        onClick={handelNavigate}
                    >
                        verify certificate
                    </button>
                    <button
                        className='text-3xl md:hidden'
                        onClick={() => setNavToggle(prev => typeof prev === 'boolean' ? !prev : false)} // Type check for safety
                    >
                        {navToggle ? <RxCross1 /> : <GiHamburgerMenu />}
                    </button>

                    <div
                        className={`${!navToggle ? "hidden" : "flex"} p-5 bg-zinc-700 absolute top-20 right-0 mx-4 my-2 min-w-[140px] z-10 rounded-xl text-white`}
                    >
                        <ul>
                            <a href='https://hexstaruniverse.com/all-classes/'>
                                <li className='hover:text-purple-400 ease-in duration-300'>All program</li>
                            </a>
                            <a href="https://hexstaruniverse.com/for-institutions/">
                                <li className='hover:text-purple-400 ease-in duration-300'>For institutions</li>
                            </a>
                            <a href="https://hexstaruniverse.com/about-us/">
                                <li className='hover:text-purple-400 ease-in duration-300'>About</li>
                            </a>
                            <button className='hover:text-purple-400 ease-in duration-300' onClick={handelUser}>
                                {user ? <>Logout</> : <>Login</>}
                            </button>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default AdminNavbar;
